import React from 'react';
import { List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import '../styles.css';
import './Sidebar.css';

const Sidebar = ({ lists, selectedList, onSelectList }) => {
  const statusLists = lists.filter(list => list.type === 'status');
  const rankedLists = lists.filter(list => list.type === 'ranked');

  return (
    <div className="sidebar">
      <Typography variant="h6">Status</Typography>
      <List>
        {statusLists.map(list => (
          <ListItem
            button
            key={list.name}
            selected={list.name === selectedList}
            onClick={() => onSelectList(list.name)}
          >
            <ListItemText primary={`${list.name} (${list.count})`} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h6">Ranked</Typography>
      <List>
        {rankedLists.map(list => (
          <ListItem
            button
            key={list.name}
            selected={list.name === selectedList}
            onClick={() => onSelectList(list.name)}
          >
            <ListItemText primary={list.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Sidebar;