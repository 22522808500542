import React from 'react';
import { Tabs, Tab } from '@mui/material';
import './Tabs.css';

const CustomTabs = ({ selectedTab, onSelectTab, viewMode, setViewMode }) => {
  const tabs = ['Books', 'Movies', 'TV Shows', 'Video Games', 'Music', 'Podcasts'];
  const views = ['Gallery', 'Stacked', 'Condensed'];

  const handleChange = (event, newValue) => {
    onSelectTab(newValue);
  };

  const handleViewChange = (event, newView) => {
    setViewMode(newView.toLowerCase());
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {tabs.map(tab => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <Tabs
        value={viewMode}
        onChange={handleViewChange}
        indicatorColor="secondary"
        textColor="secondary"
        centered
      >
        {views.map(view => (
          <Tab key={view} label={view} value={view.toLowerCase()} />
        ))}
      </Tabs>
    </>
  );
};

export default CustomTabs;