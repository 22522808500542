import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import './Card.css';

const Card = ({ item, updateItemStatus }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleChange = (e) => {
    updateItemStatus(item.id, e.target.value);
  };

  return (
    <div className="card-container">
      <div className={`card ${isFlipped ? 'flipped' : ''}`}>
        <div className="card-front">
          <select className="card-select" value={item.list} onChange={handleChange}>
            <option value="Not Started">Not Started</option>
            <option value="Queued">Queued</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Lost to Time">Lost to Time</option>
          </select>
          <img src={item.image_url} alt={item.title} className="card-image" />
          <div className="card-content-box">
            <h3>{item.title}</h3>
            <p><span className="creator-role">{item.creator_role}</span> <span className="creator-name">{item.creator_name}</span></p>
            <p>{item.release_year}</p>
          </div>
          <FontAwesomeIcon icon={faSyncAlt} className="flip-icon" onClick={handleFlip} />
        </div>
        <div 
          className="card-back"
          style={{
            backgroundImage: `linear-gradient(
              rgba(255, 255, 255, 0.95),
              rgba(255, 255, 255, 0.95)
            ), url(${item.image_url})`
          }}
        >
          <div className="card-back-content">
            <h3>{item.title}</h3>
            <p className="recommended-by">Recommended by {item.recommended_by}</p>
            <div className="description-header">Description:</div>
            <p>{item.description}</p>
            <p className="consumption-info">Available on/at</p>
            <p className="consumption-info">{item.consumption_info}</p>
          </div>
          <FontAwesomeIcon icon={faSyncAlt} className="flip-icon" onClick={handleFlip} />
        </div>
      </div>
    </div>
  );
};

export default Card;