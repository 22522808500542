export const initialItems = [
    // Books
    {
      id: 'bk_1',
      title: 'The Great Gatsby',
      content_type: 'Books',
      release_year: '1925',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/I/81QuEGw8VPL._AC_UF1000,1000_QL80_.jpg',
      creator_role: 'Author',
      creator_name: 'F. Scott Fitzgerald',
      description: 'A novel set in the Roaring Twenties...',
      consumption_info: 'Amazon Kindle',
      recommended_by: 'Liza'
    },
    {
      id: 'bk_2',
      title: 'Murder on the Orient Express',
      content_type: 'Books',
      release_year: '1934',
      list: 'Not Started',
      image_url: 'https://books.google.com/books/publisher/content?id=KKZ2DwAAQBAJ&pg=PP1&img=1&zoom=3&hl=en&bul=1&sig=ACfU3U2CqR5JkXy_IAFKzcdwc2AZa4ufIA&w=1280',
      creator_role: 'Author',
      creator_name: 'Agatha Christie',
      description: 'A thrilling mystery novel...',
      consumption_info: 'Audible'
    },
    {
      id: 'bk_3',
      title: 'Poverty By America',
      content_type: 'Books',
      release_year: '2023',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/I/61pTXBAvPUL._AC_UF1000,1000_QL80_.jpg',
      creator_role: 'Author',
      creator_name: 'Matthew Desmond',
      description: 'An insightful look into poverty in America...',
      consumption_info: 'Amazon Kindle'
    },
    {
      id: 'bk_4',
      title: 'Big Swiss',
      content_type: 'Books',
      release_year: '2022',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/I/71y2PnS64EL._AC_UF1000,1000_QL80_.jpg',
      creator_role: 'Author',
      creator_name: 'Jen Beagin',
      description: 'A quirky and humorous novel...',
      consumption_info: 'Audible'
    },
    {
      id: 'bk_5',
      title: 'Becoming',
      content_type: 'Books',
      release_year: '2018',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/I/81h2gWPTYJL._AC_UF1000,1000_QL80_.jpg',
      creator_role: 'Author',
      creator_name: 'Michelle Obama',
      description: 'A memoir by the former First Lady...',
      consumption_info: 'Amazon Kindle',
      recommended_by: 'John'
    },
    {
        id: 'bk_6',
        title: 'To Kill a Mockingbird',
        content_type: 'Books',
        release_year: '1960',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/To_Kill_a_Mockingbird_%28first_edition_cover%29.jpg/1200px-To_Kill_a_Mockingbird_%28first_edition_cover%29.jpg',
        creator_role: 'Author',
        creator_name: 'Harper Lee',
        description: 'A novel about the serious issues of rape and racial inequality...',
        consumption_info: 'Paperback',
        recommended_by: 'Alice'
      },
      {
        id: 'bk_7',
        title: '1984',
        content_type: 'Books',
        release_year: '1949',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/71kxa1-0mfL._AC_UL320_.jpg',
        creator_role: 'Author',
        creator_name: 'George Orwell',
        description: 'A dystopian social science fiction novel and cautionary tale...',
        consumption_info: 'Hardcover',
        recommended_by: 'Bob'
      },
      {
        id: 'bk_8',
        title: 'Brave New World',
        content_type: 'Books',
        release_year: '1932',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/81zE42gT3xL._AC_UF1000,1000_QL80_.jpg',
        creator_role: 'Author',
        creator_name: 'Aldous Huxley',
        description: 'A dystopian novel set in a futuristic World State...',
        consumption_info: 'E-book',
        recommended_by: 'Charlie'
      },
      {
        id: 'bk_9',
        title: 'The Catcher in the Rye',
        content_type: 'Books',
        release_year: '1951',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/81OthjkJBuL._AC_UL320_.jpg',
        creator_role: 'Author',
        creator_name: 'J.D. Salinger',
        description: 'A story about teenage alienation and rebellion...',
        consumption_info: 'Audiobook',
        recommended_by: 'Dave'
      },
      {
        id: 'bk_10',
        title: 'Pride and Prejudice',
        content_type: 'Books',
        release_year: '1813',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/81NLDvyAHrL._AC_UF1000,1000_QL80_.jpg',
        creator_role: 'Author',
        creator_name: 'Jane Austen',
        description: 'A romantic novel of manners...',
        consumption_info: 'E-book',
        recommended_by: 'Eve'
      },
    // Movies
    {
      id: 'mv_1',
      title: 'Inception',
      content_type: 'Movies',
      release_year: '2010',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/M/MV5BMjAxMzY3NjcxNF5BMl5BanBnXkFtZTcwNTI5OTM0Mw@@._V1_.jpg',
      creator_role: 'Director',
      creator_name: 'Christopher Nolan',
      description: 'A mind-bending thriller about dreams...',
      consumption_info: 'Netflix'
    },
    {
      id: 'mv_2',
      title: 'Challengers',
      content_type: 'Movies',
      release_year: '2024',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/M/MV5BNTk1MTdmYzctNzlkOS00ZTNmLWI4ZTMtMzc3OWFkMDI0MTJkXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_FMjpg_UX1000_.jpg',
      creator_role: 'Director',
      creator_name: 'Luca Guadagnino',
      description: 'An upcoming sports drama film...',
      consumption_info: 'Coming soon to theaters'
    },
    {
      id: 'mv_3',
      title: 'The Dark Knight',
      content_type: 'Movies',
      release_year: '2008',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/M/MV5BMTMxNTMwODM0NF5BMl5BanBnXkFtZTcwODAyMTk2Mw@@._V1_.jpg',
      creator_role: 'Director',
      creator_name: 'Christopher Nolan',
      description: 'Batman faces the Joker in Gotham City...',
      consumption_info: 'Netflix'
    },
    {
        id: 'mv_4',
        title: 'The Shawshank Redemption',
        content_type: 'Movies',
        release_year: '1994',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BNDE3ODcxYzMtY2YzZC00NmNlLWJiNDMtZDViZWM2MzIxZDYwXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_.jpg',
        creator_role: 'Director',
        creator_name: 'Frank Darabont',
        description: 'Two imprisoned men bond over a number of years, finding solace and eventual redemption through acts of common decency...',
        consumption_info: 'Netflix'
      },
      {
        id: 'mv_5',
        title: 'The Godfather',
        content_type: 'Movies',
        release_year: '1972',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BM2MyNjYxNmUtYTAwNi00MTYxLWJmNWYtYzZlODY3ZTk3OTFlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_.jpg',
        creator_role: 'Director',
        creator_name: 'Francis Ford Coppola',
        description: 'The aging patriarch of an organized crime dynasty transfers control of his clandestine empire to his reluctant son...',
        consumption_info: 'Amazon Prime'
      },
      {
        id: 'mv_6',
        title: 'Pulp Fiction',
        content_type: 'Movies',
        release_year: '1994',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/71c05lTE03L._AC_UL320_.jpg',
        creator_role: 'Director',
        creator_name: 'Quentin Tarantino',
        description: 'The lives of two mob hitmen, a boxer, a gangster and his wife, and a pair of diner bandits intertwine in four tales of violence and redemption...',
        consumption_info: 'Hulu'
      },
      {
        id: 'mv_7',
        title: 'The Lord of the Rings: The Fellowship of the Ring',
        content_type: 'Movies',
        release_year: '2001',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/51Qvs9i5a%2BL._AC_.jpg',
        creator_role: 'Director',
        creator_name: 'Peter Jackson',
        description: 'A meek Hobbit from the Shire and eight companions set out on a journey to destroy the powerful One Ring and save Middle-earth from the Dark Lord Sauron...',
        consumption_info: 'HBO Max'
      },
      {
        id: 'mv_8',
        title: 'Forrest Gump',
        content_type: 'Movies',
        release_year: '1994',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/91++WV6FP4L._AC_UF894,1000_QL80_.jpg',
        creator_role: 'Director',
        creator_name: 'Robert Zemeckis',
        description: 'The presidencies of Kennedy and Johnson, the Vietnam War, the Watergate scandal and other historical events unfold from the perspective of an Alabama man with an IQ of 75...',
        consumption_info: 'Netflix'
      },
      {
        id: 'mv_9',
        title: 'Fight Club',
        content_type: 'Movies',
        release_year: '1999',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/51v5ZpFyaFL._AC_.jpg',
        creator_role: 'Director',
        creator_name: 'David Fincher',
        description: 'An insomniac office worker and a devil-may-care soapmaker form an underground fight club that evolves into much more...',
        consumption_info: 'Amazon Prime'
      },
      {
        id: 'mv_10',
        title: 'Interstellar',
        content_type: 'Movies',
        release_year: '2014',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/91kFYg4fX3L._AC_UL320_.jpg',
        creator_role: 'Director',
        creator_name: 'Christopher Nolan',
        description: 'A team of explorers travel through a wormhole in space in an attempt to ensure humanity\'s survival...',
        consumption_info: 'Netflix'
      },
    // TV Shows
    {
      id: 'tv_1',
      title: 'Breaking Bad',
      content_type: 'TV Shows',
      release_year: '2008',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/M/MV5BYmQ4YWMxYjUtNjZmYi00MDQ1LWFjMjMtNjA5ZDdiYjdiODU5XkEyXkFqcGdeQXVyMTMzNDExODE5._V1_.jpg',
      creator_role: 'Creator',
      creator_name: 'Vince Gilligan',
      description: 'A high school chemistry teacher turned methamphetamine producer...',
      consumption_info: 'Netflix'
    },
    {
      id: 'tv_2',
      title: 'The Mandalorian',
      content_type: 'TV Shows',
      release_year: '2019',
      list: 'Not Started',
      image_url: 'https://m.media-amazon.com/images/M/MV5BN2M5YWFjN2YtYzU2YS00NzBlLTgwZWUtYWQzNWFhNDkyYjg3XkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg',
      creator_role: 'Creator',
      creator_name: 'Jon Favreau',
      description: 'A bounty hunter in the Star Wars universe...',
      consumption_info: 'Disney+'
    },
    {
      id: 'tv_3',
      title: 'Stranger Things',
      content_type: 'TV Shows',
      release_year: '2016',
      list: 'Not Started',
      image_url: 'https://resizing.flixster.com/0xxuABVVuzJrUT130WFHKE-irEg=/ems.cHJkLWVtcy1hc3NldHMvdHZzZWFzb24vNzUyMTFhOTktZTU4Ni00ODkyLWJlYjQtZTgxYTllZmU2OGM0LmpwZw==',
      creator_role: 'Creators',
      creator_name: 'The Duffer Brothers',
      description: 'A group of kids uncovering supernatural mysteries...',
      consumption_info: 'Netflix'
    },
    {
        id: 'tv_4',
        title: 'Game of Thrones',
        content_type: 'TV Shows',
        release_year: '2011',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BN2IzYzBiOTQtNGZmMi00NDI5LTgxMzMtN2EzZjA1NjhlOGMxXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_.jpg',
        creator_role: 'Creators',
        creator_name: 'David Benioff, D.B. Weiss',
        description: 'Nine noble families fight for control over the lands of Westeros, while an ancient enemy returns after being dormant for millennia...',
        consumption_info: 'HBO Max'
      },
      {
        id: 'tv_5',
        title: 'The Office',
        content_type: 'TV Shows',
        release_year: '2005',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BMDNkOTE4NDQtMTNmYi00MWE0LWE4ZTktYTc0NzhhNWIzNzJiXkEyXkFqcGdeQXVyMzQ2MDI5NjU@._V1_.jpg',
        creator_role: 'Creator',
        creator_name: 'Greg Daniels',
        description: 'A mockumentary on a group of typical office workers, where the workday consists of ego clashes, inappropriate behavior, and tedium...',
        consumption_info: 'Peacock'
      },
      {
        id: 'tv_6',
        title: 'Friends',
        content_type: 'TV Shows',
        release_year: '1994',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_FMjpg_UX1000_.jpg',
        creator_role: 'Creators',
        creator_name: 'David Crane, Marta Kauffman',
        description: 'Follows the personal and professional lives of six twenty to thirty-something-year-old friends living in Manhattan...',
        consumption_info: 'HBO Max'
      },
      {
        id: 'tv_7',
        title: 'The Simpsons',
        content_type: 'TV Shows',
        release_year: '1989',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BYjFkMTlkYWUtZWFhNy00M2FmLThiOTYtYTRiYjVlZWYxNmJkXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_FMjpg_UX1000_.jpg',
        creator_role: 'Creator',
        creator_name: 'Matt Groening',
        description: 'The satiric adventures of a working-class family in the misfit city of Springfield...',
        consumption_info: 'Disney+'
      },
      {
        id: 'tv_8',
        title: 'Pachinko',
        content_type: 'TV Shows',
        release_year: '2022',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BNjJjZWEyOTMtYjVkYi00MjY0LTk4OTEtMWUwMDE4NWYyODg5XkEyXkFqcGdeQXVyNjEwNTM2Mzc@._V1_.jpg',
        creator_role: 'Creator',
        creator_name: 'Soo Hugh',
        description: '....',
        consumption_info: 'Apple+'
      },
      {
        id: 'tv_9',
        title: 'Neon Genesis Evangelion',
        content_type: 'TV Shows',
        release_year: '1995',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BMTc4YTY0MDUtYWNmMi00NTRiLWE4NmItM2JiMmIzYmEwNGQzXkEyXkFqcGdeQXVyNTkwNzYyODM@._V1_.jpg',
        creator_role: 'Creator',
        creator_name: 'Hideaki Anno',
        description: '...',
        consumption_info: 'Netflix'
      },
      {
        id: 'tv_10',
        title: 'Mrs. Davis',
        content_type: 'TV Shows',
        release_year: '2021',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/8/8e/Mrs_Davis_-_Poster.jpg',
        creator_role: 'Creators',
        creator_name: 'Tara Hernandez, Damon Lindelof',
        description: '....',
        consumption_info: 'Peacock'
      },
    // Video Games
    {
      id: 'vg_1',
      title: 'Doom Eternal',
      content_type: 'Video Games',
      release_year: '2020',
      list: 'Not Started',
      image_url: 'https://image.api.playstation.com/vulcan/ap/rnd/202010/0114/b4Q1XWYaTdJLUvRuALuqr0wP.png',
      creator_role: 'Developers',
      creator_name: 'id Software',
      description: 'A fast-paced first-person shooter...',
      consumption_info: 'Steam'
    },
    {
      id: 'vg_2',
      title: 'The Legend of Zelda: Breath of the Wild',
      content_type: 'Video Games',
      release_year: '2017',
      list: 'Not Started',
      image_url: 'https://upload.wikimedia.org/wikipedia/en/c/c6/The_Legend_of_Zelda_Breath_of_the_Wild.jpg',
      creator_role: 'Developers',
      creator_name: 'Nintendo',
      description: 'An open-world adventure game...',
      consumption_info: 'Nintendo Switch'
    },
    {
        id: 'vg_3',
        title: 'The Witcher 3: Wild Hunt',
        content_type: 'Video Games',
        release_year: '2015',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/0c/Witcher_3_cover_art.jpg/220px-Witcher_3_cover_art.jpg',
        creator_role: 'Developers',
        creator_name: 'CD Projekt Red',
        description: 'An open-world RPG set in a fantasy universe full of meaningful choices and impactful consequences...',
        consumption_info: 'Steam'
      },
      {
        id: 'vg_4',
        title: 'Red Dead Redemption 2',
        content_type: 'Video Games',
        release_year: '2018',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/4/44/Red_Dead_Redemption_II.jpg',
        creator_role: 'Developers',
        creator_name: 'Rockstar Games',
        description: 'An epic tale of life in America at the dawn of the modern age...',
        consumption_info: 'PlayStation'
      },
      {
        id: 'vg_5',
        title: 'Minecraft',
        content_type: 'Video Games',
        release_year: '2011',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/5/51/Minecraft_cover.png',
        creator_role: 'Developers',
        creator_name: 'Mojang',
        description: 'A sandbox video game where players can build and explore their own virtual worlds...',
        consumption_info: 'PC'
      },
      {
        id: 'vg_6',
        title: 'Fortnite',
        content_type: 'Video Games',
        release_year: '2017',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BNzU2YTY2OTgtZGZjZi00MTAyLThlYjUtMWM5ZmYzOGEyOWJhXkEyXkFqcGdeQXVyNTgyNTA4MjM@._V1_FMjpg_UX1000_.jpg',
        creator_role: 'Developers',
        creator_name: 'Epic Games',
        description: 'A battle royale game where 100 players fight to be the last person standing...',
        consumption_info: 'PC'
      },
      {
        id: 'vg_7',
        title: 'The Elder Scrolls V: Skyrim',
        content_type: 'Video Games',
        release_year: '2011',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/1/15/The_Elder_Scrolls_V_Skyrim_cover.png',
        creator_role: 'Developers',
        creator_name: 'Bethesda Game Studios',
        description: 'An open-world RPG set in a fantastical universe full of dragons and magical creatures...',
        consumption_info: 'PlayStation'
      },
      {
        id: 'vg_8',
        title: 'Overwatch',
        content_type: 'Video Games',
        release_year: '2016',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/thumb/5/51/Overwatch_cover_art.jpg/220px-Overwatch_cover_art.jpg',
        creator_role: 'Developers',
        creator_name: 'Blizzard Entertainment',
        description: 'A team-based multiplayer first-person shooter set in a vibrant future...',
        consumption_info: 'PC'
      },
      {
        id: 'vg_9',
        title: 'Horizon Zero Dawn',
        content_type: 'Video Games',
        release_year: '2017',
        list: 'Not Started',
        image_url: 'https://image.api.playstation.com/vulcan/img/rnd/202009/2923/jAT7HjpL49A62bU7hLKXJ96b.png',
        creator_role: 'Developers',
        creator_name: 'Guerrilla Games',
        description: 'An action RPG set in a post-apocalyptic world dominated by robotic creatures...',
        consumption_info: 'PlayStation'
      },
      {
        id: 'vg_10',
        title: 'Cyberpunk 2077',
        content_type: 'Video Games',
        release_year: '2020',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/9/9f/Cyberpunk_2077_box_art.jpg',
        creator_role: 'Developers',
        creator_name: 'CD Projekt Red',
        description: 'An open-world RPG set in a dystopian future...',
        consumption_info: 'Steam'
      },
      // Music
      {
        id: 'mu_1',
        title: 'Abbey Road',
        content_type: 'Music',
        release_year: '1969',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/4/42/Beatles_-_Abbey_Road.jpg',
        creator_role: 'Artist',
        creator_name: 'The Beatles',
        description: 'The eleventh studio album by the English rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_2',
        title: 'Thriller',
        content_type: 'Music',
        release_year: '1982',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/5/55/Michael_Jackson_-_Thriller.png',
        creator_role: 'Artist',
        creator_name: 'Michael Jackson',
        description: 'The best-selling album of all time...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_3',
        title: 'The Dark Side of the Moon',
        content_type: 'Music',
        release_year: '1973',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/3/3b/Dark_Side_of_the_Moon.png',
        creator_role: 'Artist',
        creator_name: 'Pink Floyd',
        description: 'An iconic concept album by the English rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_4',
        title: 'Rumours',
        content_type: 'Music',
        release_year: '1977',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/f/fb/FMacRumours.PNG',
        creator_role: 'Artist',
        creator_name: 'Fleetwood Mac',
        description: 'The eleventh studio album by the British-American rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_5',
        title: 'Hotel California',
        content_type: 'Music',
        release_year: '1976',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/49/Hotelcalifornia.jpg/220px-Hotelcalifornia.jpg',
        creator_role: 'Artist',
        creator_name: 'Eagles',
        description: 'The fifth studio album by the American rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_6',
        title: 'Back in Black',
        content_type: 'Music',
        release_year: '1980',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/ACDC_Back_in_Black_cover.svg/1200px-ACDC_Back_in_Black_cover.svg.png',
        creator_role: 'Artist',
        creator_name: 'AC/DC',
        description: 'The seventh studio album by the Australian rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_7',
        title: 'Born in the U.S.A.',
        content_type: 'Music',
        release_year: '1984',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/3/31/BruceBorn1984.JPG',
        creator_role: 'Artist',
        creator_name: 'Bruce Springsteen',
        description: 'The seventh studio album by the American rock singer-songwriter...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_8',
        title: 'Nevermind',
        content_type: 'Music',
        release_year: '1991',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/b/b7/NirvanaNevermindalbumcover.jpg',
        creator_role: 'Artist',
        creator_name: 'Nirvana',
        description: 'The second studio album by the American rock band...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_9',
        title: 'Purple Rain',
        content_type: 'Music',
        release_year: '1984',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/81CzfbO4CrL._UF1000,1000_QL80_.jpg',
        creator_role: 'Artist',
        creator_name: 'Prince and The Revolution',
        description: 'The sixth studio album by the American recording artist...',
        consumption_info: 'Spotify'
      },
      {
        id: 'mu_10',
        title: 'The Wall',
        content_type: 'Music',
        release_year: '1979',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/I/715jbEtLNcL._UF1000,1000_QL80_.jpg',
        creator_role: 'Artist',
        creator_name: 'Pink Floyd',
        description: 'A rock opera and the eleventh studio album by the English rock band...',
        consumption_info: 'Spotify'
      },
      // Podcasts
      {
        id: 'pc_1',
        title: 'Serial',
        content_type: 'Podcasts',
        release_year: '2014',
        list: 'Not Started',
        image_url: 'https://i.podnews.network/r/t/1400/8424-baeb46d6.jpeg',
        creator_role: 'Host',
        creator_name: 'Sarah Koenig',
        description: 'A groundbreaking investigative journalism podcast...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_2',
        title: 'The Daily',
        content_type: 'Podcasts',
        release_year: '2017',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/b/b7/The_Daily_logo.jpg',
        creator_role: 'Host',
        creator_name: 'Michael Barbaro',
        description: 'A twenty-minute daily news podcast...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_3',
        title: 'Stuff You Should Know',
        content_type: 'Podcasts',
        release_year: '2008',
        list: 'Not Started',
        image_url: 'https://m.media-amazon.com/images/M/MV5BMTc0Mjg1Nzc0MF5BMl5BanBnXkFtZTcwODM5OTcwOQ@@._V1_.jpg',
        creator_role: 'Hosts',
        creator_name: 'Josh Clark, Chuck Bryant',
        description: 'A podcast explaining how things work...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_4',
        title: 'Radiolab',
        content_type: 'Podcasts',
        release_year: '2002',
        list: 'Not Started',
        image_url: 'https://i.scdn.co/image/ab6765630000ba8a44d70adc5a19fadd97cce181',
        creator_role: 'Hosts',
        creator_name: 'Jad Abumrad, Robert Krulwich',
        description: 'A show about curiosity where sound illuminates ideas...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_5',
        title: 'This American Life',
        content_type: 'Podcasts',
        release_year: '1995',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Thisamericanlife-wbez.png',
        creator_role: 'Host',
        creator_name: 'Ira Glass',
        description: 'A weekly public radio program and podcast...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_6',
        title: 'The Joe Rogan Experience',
        content_type: 'Podcasts',
        release_year: '2009',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/4/4b/The_Joe_Rogan_Experience_logo.jpg',
        creator_role: 'Host',
        creator_name: 'Joe Rogan',
        description: 'A long-form, in-depth conversation hosted by comedian Joe Rogan...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_7',
        title: 'How I Built This',
        content_type: 'Podcasts',
        release_year: '2016',
        list: 'Not Started',
        image_url: 'https://upload.wikimedia.org/wikipedia/en/b/b0/NPR_How_I_Built_This_cover_art.jpg',
        creator_role: 'Host',
        creator_name: 'Guy Raz',
        description: 'Stories behind the people who created some of the world\'s best known companies...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_8',
        title: 'Planet Money',
        content_type: 'Podcasts',
        release_year: '2008',
        list: 'Not Started',
        image_url: 'https://media.npr.org/assets/img/2022/10/24/pm_new_tile_2022_sq-b4af5aab11c84cfae38eafa1db74a6da943d4e7f.jpg?s=2600&c=100&f=jpeg',
        creator_role: 'Hosts',
        creator_name: 'Various',
        description: 'Explaining the complexities of economics in an accessible and entertaining way...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_9',
        title: 'Freakonomics Radio',
        content_type: 'Podcasts',
        release_year: '2010',
        list: 'Not Started',
        image_url: 'https://i.scdn.co/image/ab6765630000ba8ad8cbc1b0e35fb151ae472695',
        creator_role: 'Host',
        creator_name: 'Stephen Dubner',
        description: 'Exploring the hidden side of everything...',
        consumption_info: 'Spotify'
      },
      {
        id: 'pc_10',
        title: 'TED Talks Daily',
        content_type: 'Podcasts',
        release_year: '2012',
        list: 'Not Started',
        image_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnEEI9CtN342MCImnkQ2sczRR9tezwJpyuag&s',
        creator_role: 'Host',
        creator_name: 'Various',
        description: 'Every weekday, TED Talks Daily brings you the latest talks in audio...',
        consumption_info: 'Spotify'
      }
  ];