import React from 'react';
import './StackedCardList.css'; // Ensure this path is correct
import Card from './Card'; // Ensure this path is correct

const StackedCardList = ({ items, updateItemStatus }) => {
  return (
    <div className="stacked-card-list">
      {items.map((item, index) => (
        <div 
          key={item.id} 
          className="stacked-card-wrapper" 
          style={{ left: `calc(var(--card-spacing) * ${index})` }}
        >
          <Card item={item} updateItemStatus={updateItemStatus} />
        </div>
      ))}
    </div>
  );
};

export default StackedCardList;