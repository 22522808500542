import React from 'react';
import './CondensedCardList.css';
import Card from './Card';

const CondensedCardList = ({ items, updateItemStatus }) => {
  return (
    <div className="condensed-card-list">
      {items.map((item) => (
        <div key={item.id} className="condensed-card-wrapper">
          <div 
            className="condensed-card"
            style={{ backgroundImage: `url(${item.image_url})` }}
          >
            <div className="condensed-card-title-box">
              <div className="condensed-card-title">
                {item.title}
              </div>
            </div>
          </div>
          <div className="full-card">
            <Card item={item} updateItemStatus={updateItemStatus} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CondensedCardList;