import React from 'react';
import Card from './Card';
import Grid from '@mui/material/Grid';
import '../styles.css';

const CardList = ({ items, updateItemStatus }) => {
  return (
    <Grid container spacing={2} className="card-list">
      {items.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <Card item={item} updateItemStatus={updateItemStatus} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardList;