import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './components/Header';
import Tabs from './components/Tabs';
import CardList from './components/CardList';
import Sidebar from './components/Sidebar';
import StackedCardList from './components/StackedCardList'; // Import the new component
import CondensedCardList from './components/CondensedCardList'; // Import the new component
import './styles.css'; // Ensure you have added the gradient background to this file

// Import the initialItems from the separate file
import { initialItems } from './initialItems';

// Log the imported initialItems
console.log('Initial Items:', initialItems);

const App = () => {
  // Initialize state with an empty array as fallback
  const [items, setItems] = useState(initialItems || []);
  const [selectedTab, setSelectedTab] = useState('Books');
  const [selectedList, setSelectedList] = useState('Not Started');
  const [viewMode, setViewMode] = useState('gallery'); // Add view mode state

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleSelectList = (list) => {
    setSelectedList(list);
  };

  const updateItemStatus = (id, list) => {
    const updatedItems = items.map(item =>
      item.id === id ? { ...item, list } : item
    );
    setItems(updatedItems);
  };

  const filteredItems = items.filter(item => item.content_type === selectedTab && item.list === selectedList);

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Header />
        <Tabs selectedTab={selectedTab} onSelectTab={handleSelectTab} viewMode={viewMode} setViewMode={setViewMode} />
        <Box display="flex" mt={2}>
          <Sidebar
            lists={[
              { name: 'Not Started', count: items.filter(item => item.content_type === selectedTab && item.list === 'Not Started').length, type: 'status' },
              { name: 'Queued', count: items.filter(item => item.content_type === selectedTab && item.list === 'Queued').length, type: 'status' },
              { name: 'In Progress', count: items.filter(item => item.content_type === selectedTab && item.list === 'In Progress').length, type: 'status' },
              { name: 'Completed', count: items.filter(item => item.content_type === selectedTab && item.list === 'Completed').length, type: 'status' },
              { name: 'Lost to Time', count: items.filter(item => item.content_type === selectedTab && item.list === 'Lost to Time').length, type: 'status' },
              { name: '2023', count: items.filter(item => item.content_type === selectedTab && item.release_year === '2023').length, type: 'ranked' },
              { name: '2022', count: items.filter(item => item.content_type === selectedTab && item.release_year === '2022').length, type: 'ranked' },
              { name: '2021', count: items.filter(item => item.content_type === selectedTab && item.release_year === '2021').length, type: 'ranked' },
              { name: '2020', count: items.filter(item => item.content_type === selectedTab && item.release_year === '2020').length, type: 'ranked' },
            ]}
            selectedList={selectedList}
            onSelectList={handleSelectList}
          />
          {viewMode === 'gallery' && <CardList items={filteredItems} updateItemStatus={updateItemStatus} />}
          {viewMode === 'stacked' && <StackedCardList items={filteredItems} updateItemStatus={updateItemStatus} />}
          {viewMode === 'condensed' && <CondensedCardList items={filteredItems} updateItemStatus={updateItemStatus} />}
        </Box>
      </Box>
    </Container>
  );
};

export default App;